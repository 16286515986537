:root {
  --primary-blue: #2f49d1;
  --error: #f26464;
  --app-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: unset;
  font-size: unset;
  line-height: unset;
  background: '#fff';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  min-width: 320px;
}

.wrapper_app {
  margin: 76px 0 0;
  min-height: calc(var(--app-height) - 76px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.slider_arrows_container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: max-content;
}

.slider_arrow_left_arrival {
  position: absolute;
  left: 0.5rem;
}

.slider_arrow_right_arrival {
  position: absolute;
  right: 0.5rem;
}

.slider_container {
  overflow: hidden;
}
.slick-prev {
  z-index: 1;
}
.slider_container .slick-dots {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
  padding: 10;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slider_container .slick-dots li button:before {
  color: #ffffff;
  font-size: 10px;
}

.slider_container .slick-dots li.slick-active button:before {
  font-size: 10px;
  color: #ffffff;
}

.slider_arrow_left_related {
  position: absolute;
  left: 0.5rem;
}
.slider_arrow_right_related {
  position: absolute;
  right: 0.5rem;
}
